<template>
    <AccordionItem
        :key="STATES.ACTIVE"
        :active-item-key="state"
        :is-active-scroll-to="false"
        class="faq-list-item"
        @accordion-toggle="toggleContent()"
    >
        <template #accordion-title>
            <HeaderText :tag="HEADER_TEXT_TAG" :size="HEADER_TEXT_SIZE" class="title">
                {{ title }}
            </HeaderText>
        </template>
        <template #accordion-content>
            <BaseParagraph class="description">
                <slot />
            </BaseParagraph>
        </template>
    </AccordionItem>
</template>

<script>
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import AccordionItem from '@molecules/Accordion/AccordionItem';

import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
} from '@modivo-ui/components/HeaderText/v1';

const STATES = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
};

export default {
    name: 'FAQListItem',

    components: {
        HeaderText,
        BaseParagraph,
        AccordionItem,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            state: STATES.INACTIVE,
        };
    },

    beforeCreate() {
        this.STATES = STATES;
        this.HEADER_TEXT_TAG = HEADER_TEXT_TAGS.H5;
        this.HEADER_TEXT_SIZE = HEADER_TEXT_SIZES.S;
    },

    methods: {
        toggleContent() {
            this.state = this.state === STATES.ACTIVE ? STATES.INACTIVE : STATES.ACTIVE;
        },
    },
};
</script>

<style lang="scss" scoped>
.faq-list-item {
    @apply border-b-1 border-border;

    &:last-child {
        @apply border-b-1;
    }

    .title {
        @apply my-3 font-book text-tertiary text-left;
    }

    .description {
        @apply text-s leading-s;
    }
}
</style>
